import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0059 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="医師の働き方改革！分類別の適用内容や取り組むべき内容・具体策は？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.10.13</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        2019年4月1日の施行から5年の猶予期間を経て、2024年4月1日から「聖域」とされていた医師に対する時間外労働の上限規制が課せられます。一般職とは明らかに働き方が異なる職種ではあるものの、研修医の過労死問題などの社会的に衝撃を与えた背景もあり、正に「働き方改革」が求められます。今回は医師の働き方改革について、具体策や表も用いて、解説します。{' '}
      </p>
      <img src="/images/column/details/c0059.jpg" alt="キンクラコラム画像59" />
      <h1 id="toc1">2024年4月1日から適用される内容（一般職）</h1>
      <p>
        これまで事実上、上限がなかった時間外労働時間数に上限が課されることとなります。建設業等を除く一般職では原則として、１か月あたり45時間、年間360時間が上限となりますが、労使合意の元「特別条項」を締結することで、
        <span>
          単月100時間未満、2～6か月の複数月平均80時間以下（休日労働含む）、年間720時間以下（休日労働除く）が上限
        </span>
        となります。
      </p>

      <h1 id="toc2">2024年4月1日から適用される内容（医師）</h1>
      <p>
        偏に医師と言っても病院の規模や立場によって働き方はひとくくりにはできませんので、次の4つの分類に分けられます。
        <br />
        <br />
        ・A水準
        <br />
        ・B水準
        <br />
        ・連携B水準
        <br />
        ・C水準（C-1水準・C-2水準）
        <br />
        <br />
        まず、A水準とは時間外労働の上限時間数が960時間となります。本来はこの水準を目指すべきではありますが、集中的に手技を学ぶ必要があるという時期や受け入れ患者数が集中する規模が大きい病院の場合、A水準での運用は難しい場合もあり得ます。その場合、後述するB水準等の「特例水準」を目指していくこととなります。B水準とは端的には
        <span>「地域医療提供体制」の確保を目的</span>
        に暫定的に設定されている水準です。具体的には重篤患者や特殊疾患の患者を受け入れる医療機関や、救急車を一定以上受け入れる医療機関が対象です。続いて、連携B水準とは当該医療機関のみでは年間の時間外労働時間数が960時間以内であるものの、
        <span>複数の医療機関と合算すると、960時間を超えてしまう場合</span>
        の水準です。医師の場合、複数の医療機関で診療業務に従事することは珍しくありませんので、このような水準も設けられているということです。そして、B水準、連携B水準ともに
        <span>年間の時間外労働時間数は1,860時間以内が上限</span>
        です。最後にC水準とは同水準の中でも2つにわけられ、医師であっても特に高度の専門的知識や手技を一定期間に集中的に学ぶ必要性が高く、我が国の公益上も必要性が高いと認められる場合の「集中的技能向上水準」とされます。C水準の中の１つであるC-1水準とは、
        <span>初期臨床研修医や一定の要件を満たした後期臨床研修医が対象</span>
        となり、予め作成された研修計画に沿って一定期間、集中的に多くの手技や症例の研鑽を積むこととなります。次にC-2水準とは、
        <span>
          医籍登録後6年以上の医師が高度な手技を身に着けるために公益上必要
        </span>
        と認められる場合です。
        <br />
        <br />
        なお、Ａ水準の場合、面接指導と就業上の措置は義務となりますが、連続勤務時間制限28時間、勤務間インターバル9時間の確保、代償休息は努力義務となります。他方、他の水準では面接指導等全てが義務となります。
        <br />
      </p>
      <img
        src="/images/column/details/c0059_g01.jpg"
        alt="キンクラコラム画像59"
      />
      <p>
        出典 厚生労働省
        <br />
      </p>
      <a href="https://www.mhlw.go.jp/content/10800000/000818136.pdf">
        https://www.mhlw.go.jp/content/10800000/000818136.pdf
      </a>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">例外的な取り扱いとは</h1>
      <p>
        例えばA水準では単月は100時間未満とされているものの、「例外あり」とされています。これは具体的には過労死防止の観点から、医師による面接指導後に当該面接指導の結果を鑑みて必要な就業上の措置を行う場合は例外的に100時間以上となっても（望ましい状態でないことはもちろんですが）直ちに法違反とはならないということです。
      </p>

      <h1 id="toc4">改革に向けて取り組むべきこと・具体策</h1>
      <h2 id="toc4-1">タスクシフティング</h2>
      <p>
        当たり前のように医師が行ってきた業務の中には医師でなくても行える業務が含まれているケースは少なくありません。2024年4月1日になったからといっても働き方が同じであれば法令順守は難しいと言えます。よって、
        <span>施行日から逆算して進めていく必要</span>
        があります。もちろん「診断」や「治療」は医師しかすることはできませんが、看護師や、看護助手の他に最近では
        <span>
          医師事務作業補助者という医師が行う診断書作成等の事務作業を補助
        </span>
        するスタッフの存在がクローズアップされ始めています。もちろん人員補充が最も確実な方法ではありますが、そもそも医師の場合、簡単に採用できるわけではありませんし、１人採用するだけでも医師の場合は相当な人件費増となることは想像に難くありません。
      </p>
      <h2 id="toc4-2">医療勤務環境改善支援センターの活用</h2>
      <p>
        医師等の
        <span>医療従事者の就労環境改善のための相談対応を担ってくれる機関</span>
        です。自院の中だけでは具体的な対応策が思い浮かばないという場合、経験豊富な同センターの知見を積極的に活用すべきです。他の医療機関での取り組み事例が全て活用できるとは言えませんが、何らかのヒントになることは考えられます。特に「特例水準」の検討をする場合、別組織である医療機関勤務環境評価センターの評価を受ける必要がありますので、その前段階で活用する医療機関が多いです。
      </p>

      <h1 id="toc5">最後に</h1>
      <p>
        医師の働き方改革が進んでいくことによって医師が健康な状態で働き続けられ、ひいては国民への良質な医療提供が可能になると示唆されています。言うまでもなく、医師は国民の健康と密接に関わる最たる職種であることから、一部例外的な取り扱いはやむを得ないものの、実効性のある「働き方改革」が求められています。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0059
